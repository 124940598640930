<template>
  <div class="fd-w-full fd-bg-white fd-rounded-lg fd-px-4 fd-pt-6 fd-pb-2" style="min-height: 100%; height: auto;">
    <Table
      :name="name"
      :total="total"
      :fields="fields"
      :items="properties"
      :hasFilter="true"
      :hasSearch="true"
      :perPage="10"
      :isBusy="isBusy"
      @changedPage="setPage"
      @setSearch="setSearch"
      @showRemoveComponent="showRemoveComponent"
      @showEditComponent="editProperty"
      @viewComponent="showProperty"
      @applyFilter="applyFilter"
      @clearAllFilters="clearAllFilters"
      :hasViewIcon="true"
    >
      <template #filters>
        <PropertyFilter @filters="setFilters" type="project" />
      </template>
      <template #footer-tab>
        <div>
          <button
            @click.prevent="showImportProperty"
            class="fd-block fd-w-9 fd-h-9 fd-border fd-border-theme-4 fd-flex fd-justify-center fd-items-center fd-rounded-lg"
          >
            <SvgLoader :name="'exportIcon'" />
          </button>
          <button
            @click.prevent="showAddPropertySidebar"
            class="fd-block fd-w-9 fd-h-9 fd-bg-theme-4 fd-flex fd-justify-center fd-items-center fd-mt-2 fd-rounded-lg"
          >
            <SvgLoader :name="'add'" />
          </button>
        </div>
      </template>
    </Table>

    <ConfirmDialogue @remove="removeProperty" />

    <div>
      <b-sidebar
        id="import-sidebar"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <ImportProperty :postAction="`/projects/${$route.params.id}/property/import`" />
        </template>
      </b-sidebar>

      <b-sidebar
        id="add-property-sidebar"
        no-header
        bg-variant="white"
        shadow right lazy backdrop
        :backdrop-variant="'dark'"
      >
        <template #default="{ hide }">
          <PropertyForm
            type="project"
            :isUpdate.sync="isUpdate"
            :readOnly.sync="readOnly"
            :propertyId="propertyId"
            @setForm="setForm"
            @saveData="save"
          />
        </template>

      </b-sidebar>
    </div>
  </div>
</template>

<script>
import Table from "@/views/components/global/Table";
import {mapGetters} from "vuex";
import { BButton, BSidebar } from 'bootstrap-vue'
import PropertyFilter from "@/views/components/ProjectManagement/property/PropertyFilter";
import ImportProperty from "@/views/components/ProjectManagement/property/ImportProperty";
import PropertyForm from "@/views/components/ProjectManagement/property/PropertyForm";
import ConfirmDialogue from "@/views/components/global/ConfirmDialogue";
import toast from "@/mixins/toast";

export default {
  name: "Property",
  mixins: [toast],
  components: {ConfirmDialogue, PropertyForm, ImportProperty, PropertyFilter, Table, BButton, BSidebar},
  data() {
    return {
      loading: false,
      btnTitle: null,
      isUpdate: false,
      readOnly: false,
      propertyId: null,
      search: '',
      filters: '',
      form: null,
      itemId: null
    }
  },
  created() {
    let data = {
      id: this.$route.params.id
    }
    this.getData(data)
  },
  watch: {
    search: {
      handler(newVal) {
        let data = {
          id: this.$route.params.id,
        }
        if (newVal) {
          data.search = newVal
        }

        if (this.filters) {
          data.filters = this.filters
        }

        this.getData(data)
      }
    }
  },
  computed: {
    ...mapGetters({
      properties: 'project/properties',
      total: 'project/propertiesTotal'
    }),
    fields() {
      return [
        { key: 'phase', label: 'PHASE' },
        { key: 'complex', label: 'COMPLEX' },
        { key: 'template', label: 'TEMPLATE' },
        { key: 'type', label: 'TYPE' },
        { key: 'floor', label: 'FLOOR' },
        { key: 'rooms', label: 'ROOMS' },
        { key: 'unit_number', label: 'UNIT NUM' },
        { key: 'net', label: 'NET' },
        { key: 'gross', label: 'GROSS' },
        { key: 'price', label: 'PRICE' },
        { key: 'status', label: 'STATUS', sortable: true },
        { key: 'actions', label: 'ACTIONS', class: 'text-right' }
      ]
    },
    name() {
      return 'Properties'
    },
    isBusy() {
      return this.loading
    }
  },
  methods: {
    getData(data) {
      this.loading = true
      data.id = this.$route.params.id
      this.$store.dispatch('project/getProperties', data).finally(() => {
        this.loading = false
      })
    },
    setPage(number) {
      let data = {
        id: this.$route.params.id,
        number
      }
      if (this.search) {
        data.search = this.search
      }
      if (this.filters) {
        data.filters = this.filters
      }

      this.getData(data)
    },
    setForm(formData) {
      this.form = formData
    },
    setSearch(search) {
      this.search = search
    },
    setFilters(filters) {
      this.filters = filters
    },
    showImportProperty() {
      this.$root.$emit('bv::toggle::collapse', 'import-sidebar')
    },
    showAddPropertySidebar() {
      this.$root.$emit('bv::toggle::collapse', 'add-property-sidebar')
    },
    removeProperty() {
      this.$store.dispatch('project/deleteProperty', this.itemId).then(response => {
        this.makeToast(response.data.message)
        let data = {
          id: this.$route.params.id,
        }
        if (this.search) {
          data.search = this.search
        }
        if (this.filters) {
          data.filters = this.filters
        }

        this.getData(data)
      })
        .finally(() => {
          this.itemId = null
          this.toggleConfirmDialogue()
        })
    },
    showRemoveComponent(id) {
      this.itemId = id
      this.toggleConfirmDialogue()
    },
    toggleConfirmDialogue() {
      this.$root.$emit("bv::toggle::modal", "confirmDialogue");
    },
    editProperty(id) {
      this.isUpdate = true
      this.propertyId = id
      this.showAddPropertySidebar()
    },
    showProperty(id) {
      this.readOnly = true
      this.propertyId = id
      this.showAddPropertySidebar()
    },
    save(actionType) {
      let data = {
        data: JSON.parse(JSON.stringify(this.form))
      }

      data.data.phase_id = data.data.phase.id
      data.data.complex_id = data.data.complex.id
      data.data.template_id = data.data.template.id
      data.data.discount_percent = data.data.payment.cash.discount
      data.data.public_price = data.data.main_price
      data.data.installment_discount_percent = data.data.payment.installment.discount
      data.data.installment_month_count = data.data.payment.installment.month

      const whiteList = ['complex_id', 'template_id', 'phase_id', 'extra_info', 'visibility', 'floor', 'unit_number', 'public_price', 'discount_percent', 'installment_discount_percent', 'installment_month_count']
      const checkNumberList = ['floor', 'unit_number', 'public_price', 'discount_percent', 'installment_discount_percent', 'installment_month_count']

      Object.keys(data.data).forEach(key => {
        if (!whiteList.includes(key)) {
          delete data.data[key]
        }
        if (actionType === 'createNewProperty' && (data.data[key] === "" || data.data[key] === null)) {
          delete data.data[key]
        } else {
          if (checkNumberList.includes(key)) {
            data.data[key] = Number(data.data[key])
          }
        }
      })

      if (this.isUpdate) {
        data.id = this.$route.params.id
        data.propertyId = this.propertyId
      }
      this.$store.dispatch(`project/${actionType}`, data).then(() => {
        data = {
          id: this.$route.params.id
        }
        if (this.search) {
          data.search = this.search
        }
        if (this.filters) {
          data.filters = this.filters
        }
        this.getData(data)
        this.showAddPropertySidebar()
      })
    },
    applyFilter() {
      if (!this.filters) return

      let data = {
        id: this.$route.params.id,
        filters: this.filters
      }
      if (this.search) {
        data.search = this.search
      }
      this.getData(data)
    },
    clearAllFilters() {
      this.search = ''
      let data = {
        id: this.$route.params.id,
      }
      this.getData(data)
    }
  }
}
</script>

<style scoped>

</style>